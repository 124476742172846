<template>
  <section>
    <div class="content-header">
      <h2>Resilience strategies</h2>
    </div>
    <!-- <h3>CAROUSEL</h3> -->
    <div class="content-wrapper">
      <p>Get a visual glimpse of how to plan for academic tenacity in your classroom with the helpful resource <a
          href="https://wellbeing.ubc.ca/sites/wellbeing.ubc.ca/files/u9/2020-04-01%20%20Participation.pdf"
          target="_blank"
        >Fostering Academic Tenacity: Promoting Participation</a> (licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">CC BY-NC-SA 4.0</a>) from the University of British Columbia.</p>
        <a href="https://wellbeing.ubc.ca/sites/wellbeing.ubc.ca/files/u9/2020-04-01%20%20Participation.pdf" target="_blank">
          <img src="@/assets/img/_content/academic-share-1.png" class="img-fluid img-thumb" alt="" srcset="">
        </a>
        <p class="text-center">Fostering Academic Tenacity: Promoting Participation, University of British Columbia</p>
      <!-- <div class="row align-items-center">
        <h3>Promoting Participation</h3>
        <div class="col-sm-6">
          <p>Get a visual glimpse of <a
            href="https://wellbeing.ubc.ca/sites/wellbeing.ubc.ca/files/u9/2020-04-01%20%20Participation.pdf"
            target="_blank"
          >how to plan for academic tenacity</a> in your classroom with this online resource.</p>
        </div>
        <div class="col-sm-6">
          <a href="https://wellbeing.ubc.ca/sites/wellbeing.ubc.ca/files/u9/2020-04-01%20%20Participation.pdf" target="_blank">
            <img src="@/assets/img/_content/academic-share-1.png" class="img-fluid img-thumb" alt="" srcset="">
          </a>
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
